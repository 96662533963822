
; $ = global.$ = require("jQuery");
; var __browserify_shim_require__=require;(function browserifyShim(module, exports, require, define, browserify_shim__define__module__export__) {
// var $ = require('jQuery');

var mapIt = {

  map: false,
  markers: {},

  initGmaps: function() {
      if ($('#gmaps').length != 0) {


        // $(window).outerWidth();
          // if ('pad-' in mbiMq.mqArea) {
          //     var lat = 50.438070;
          //     var lng = 7.398080;
          //     var drag = false;
          //     var scrolli = false;
          // } else {
              var lat = 47.071630;
              var lng = 15.434306;
              var drag = true;
              var scrolli = false;
          // }

          // var zoom = 14;
          // if ('paw-' in mbiMq.mqArea) {
              var zoom = 16;
              // var zoom = 19;
          // }

          mapIt.map = new GMaps({
              div: '#gmaps',
              lat: lat,
              lng: lng,
              zoom: zoom,
              draggable: drag,
              refresh:true,
              // scrollwheel:scrolli,
              styles: [
{
"elementType": "geometry",
"stylers": [
{
  "color": "#e9e8e2"
}
]
},
{
"elementType": "labels.icon",
"stylers": [
{
  "visibility": "off"
}
]
},
{
"elementType": "labels.text.fill",
"stylers": [
{
  "color": "#ad976e"
}
]
},
{
"featureType": "administrative.land_parcel",
"elementType": "labels.text.fill",
"stylers": [
{
  "color": "#ad976e"
}
]
},
{
"featureType": "poi",
"elementType": "geometry",
"stylers": [
{
  "color": "#eeeeee"
}
]
},
{
"featureType": "poi",
"elementType": "labels.text.fill",
"stylers": [
{
  "color": "#ad976e"
}
]
},
{
"featureType": "poi.park",
"elementType": "geometry",
"stylers": [
{
  "color": "#d4c4a9"
}
]
},
{
"featureType": "poi.park",
"elementType": "labels.text.fill",
"stylers": [
{
  "color": "#ad976e"
}
]
},
{
"featureType": "road",
"elementType": "geometry",
"stylers": [
{
  "color": "#f5f3f3"
}
]
},
{
"featureType": "road.arterial",
"elementType": "labels.text.fill",
"stylers": [
{
  "color": "#9e9e9e"
}
]
},
{
"featureType": "road.highway",
"elementType": "geometry",
"stylers": [
{
  "color": "#dadada"
}
]
},
{
"featureType": "road.highway",
"elementType": "labels.text.fill",
"stylers": [
{
  "color": "#ad976e"
}
]
},
{
"featureType": "road.local",
"elementType": "labels.text.fill",
"stylers": [
{
  "color": "#9e9e9e"
}
]
},
{
"featureType": "transit.line",
"elementType": "geometry",
"stylers": [
{
  "color": "#e5e5e5"
}
]
},
{
"featureType": "transit.station",
"elementType": "geometry",
"stylers": [
{
  "color": "#eeeeee"
}
]
},
{
"featureType": "water",
"elementType": "geometry",
"stylers": [
{
  "color": "#99aecb"
}
]
},
{
"featureType": "water",
"elementType": "labels.text.fill",
"stylers": [
{
  "color": "#d3d2d4"
}
]
}
]
              // featuretype list: https://developers.google.com/maps/documentation/javascript/style-reference#style-features
              // https://mapstyle.withgoogle.com/
          });

          var urlPrefix = $('html').attr('data-assets-url');

          var image = {
              url: urlPrefix + '/Svg/google_pin_small.png',
              size: new google.maps.Size(144, 202),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(0,202),
          }
          // var lat = 47.070430;
          // var lng = 15.434406;


          // clickable: true,
          // map: map,
          // position: center_location,
          // icon: marker_image,

          // new google.maps.Point(0, 100)
          var marker = {
              // lat: 47.070430,
              // lng: 15.435706,
              position: new google.maps.LatLng(47.070430,15.434406),
              icon:image,
              click: function(e) {
                  window.open('https://www.google.at/maps/place/Salon+Marie+%7C+Restaurant+%26+Bar+in+Graz/@47.070439,15.4323143,17z/data=!3m1!4b1!4m5!3m4!1s0x476e35784511d7e5:0x56956428464a9917!8m2!3d47.070439!4d15.434503');
              }
//

          }
          console.log(mapIt.map);
          mapIt.map.addMarker(marker);

      }
  }
}

  
mapIt.initGmaps();

mapIt.exports = mapIt;

; browserify_shim__define__module__export__(typeof mapIt != "undefined" ? mapIt : window.mapIt);

}).call(global, undefined, undefined, undefined, undefined, function defineExport(ex) { module.exports = ex; });
