// var $ = require('jQuery')

require('intersection-observer');
var debounce   = require('js-throttle-debounce');


// var flickity   = require('flickity');
// var barba    = require('@barba/core');
var flickIt        = require('flickIt');
var cookIt        = require('cookIt');
var mapIt        = require('mapIt');
var init       = require('./footer_init.js');
var bodyScroll = require('body-scroll-toggle')
// var countUp = require('/Vendor/countUp.withPolyfill.min.js');
// var countUp = require('/Vendor/countUp.jquery.js');
// var CountUp    = require('countup.js');
// import { CountUp } from 'countup.js';


$(document).ready(function() {

$('html').addClass('is_fading');

/*===================================
=            global vars            =
===================================*/

var windowWidth = $(window).width();


/*=============================
=            barba            =
=============================*/

// barba.init({
//     transitions: [{
//         leave({ current, next, trigger }) {

//             const done = this.async();
//             setTimeout(function () {
//                 done();
//             }, 500);
//         },
//         enter({ current, next, trigger }) {
//         },
//         afterEnter({ current, next, trigger }) {
//         }
//     }],
// });
// // set new body class
// barba.hooks.afterLeave((data) => {
//     // Set <body> classes for "next" page
//     var nextHtml = data.next.html;
//     var response = nextHtml.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', nextHtml)
//     var bodyClasses = $(response).filter('notbody').attr('class')
//     $("body").attr("class", bodyClasses);
//     // changeActiveMenu();
// });


/*===================================
=            repeatOften            =
===================================*/

function repeatOften() {

    if ($(window).scrollTop() != 0) {
        if (!$('nav').hasClass('is_scrolled')) {
            $('nav').addClass('is_scrolled');
        }
    } else {
        if ($('nav').hasClass('is_scrolled')) {
            $('nav').removeClass('is_scrolled');
        }
    }
}

$(window).on('scroll', function() {
    requestAnimationFrame(repeatOften);
});
repeatOften();

/*===================================
=            hash loader            =
===================================*/

$(document).on('click', '.js_linkAnkerHandler', function(e) {
    var $this = $(this);
    var url = $this.attr('data-slug');

    if ($this.hasClass('menu-overlay-item')) {
    //     bodyScroll.enable();
        closeMenu();
    }
    setTimeout(function() {
        scrollToElement(url);
    }, 100);
    // setTimeout(function() {
    //  document.location.hash = url;
    // }, 500);
});

// $(document).on('keypress', '.js_linkAnkerHandler', function(e) {
//     if(e.which == 13 || e.which == 32){//Enter key pressed
//         $(this).click();//Trigger search button click event
//     }
// });

function scrollToElement(hash) {

    if ($('#' + hash).length) {
        // var ww = $(window).outerWidth();
        // if (ww <= 1024) {
        //     var minus = 70;
        // } else {
        //     var minus = 100;
        // }

        var $hashItem = $('#' + hash);

        // console.log($hashItem.offset().top - 70);
        $([document.documentElement, document.body]).animate({
            scrollTop: $hashItem.offset().top - 70
        }, 700);
    }
};

var url = window.location.href;
var hash = url.substring(url.indexOf("#")+1);
// console.log(hash);
if (url.indexOf("#") !== -1) {
    setTimeout(function() {
        scrollToElement(hash);
    }, 500);
}

/*=================================
=            nl submit            =
=================================*/



function validateForm(form) {
    var formLength = form.length;
    if (formLength >= 2) {
        for (var i = form.length - 1; i >= 0; i--) {
            if (form[i].name == 'email') {
                if (validateEmail(form[i].value)) {
                    return true;
                } else {
                    errorEmail();
                }
            }
        }
    } else {
        for (var i = form.length - 1; i >= 0; i--) {
            if (form[i].name == 'email') {
                if (!validateEmail(form[i].value)) {
                    errorEmail();   
                }
            }
        }
        errorDatenschutz();
        return false;
    }
}

function errorDatenschutz() {
    var errorTime = 4000;
    $('form .input-wrapper-datenschutz').addClass('has_error');
    setTimeout(function() {
        $('form .input-wrapper-datenschutz').removeClass('has_error');
    }, errorTime);
}

function errorEmail(type) {
    var errorclass = '';
    if (type == 'exists') {
        errorclass = 'error-exists';
    } else if(type == 'blocked') {
        errorclass = 'error-blocked';
    } else {
        errorclass = '';
    }

    var errorTime = 4000;
    $('form .input-wrapper-email').addClass('has_error').addClass(errorclass);
    setTimeout(function() {
        $('form .input-wrapper-email').removeClass('has_error').removeClass('error-exists').removeClass('error-blocked');
    }, errorTime);


}

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


$(document).on('click', '.js_subNews', function(e) {
    e.preventDefault();
    var $this = $(this);
    if (!$this.hasClass('is_locked')) {
        $this.addClass('is_locked');

        var form = $(this).closest('form').serializeArray();

        // console.log(form);
        if (validateForm(form)) {
            $.ajax({
                type: 'POST',
                dataType: 'json',
                data: {
                    action: 'mailchimp',
                    form: form
                },
            })
            .done(function(data) {
                console.log("success");
                // console.log(data);
                var data = JSON.parse(data)
                console.log(data);
                console.log(data.status);
                if (data.status == 'subscribed') {
                    $('form .input-wrapper-email').remove('');
                    $('form .input-wrapper-datenschutz').remove('');
                    $('form .submit').html('Danke für die Anmeldung.');

                } else {
                    if (data.title == "Member Exists") {
                        errorEmail('exists');
                    // } else if(data.title == "Forgotten Email Not Subscribed"){
                    } else {
                        errorEmail('blocked');
                        
                    }

                }
            })
            .fail(function() {
                console.log("error");
            })
            .always(function() {
                $this.removeClass('is_locked');
                console.log("complete");
            });
        } else {
            $this.removeClass('is_locked');

        }
    
    }
});

/*========================================
=            js open readmore            =
========================================*/

$(document).on('click', '.js_openReadmore',function (e) {
    e.preventDefault();
    var $this = $(this);
    var $wrapper = $(this).closest('.section');
    var $contentWrapper = $wrapper.find('.readmore');
    var $content = $wrapper.find('.readmore-inner');
    var contentHeight = $content.outerHeight();
    var closeText = $this.attr('data-button-text-close');
    var buttonText = $this.attr('data-button-text-standard');

    if ($this.hasClass('is-active')) {
        $contentWrapper.css('max-height', 0);
        $this.html(buttonText);

        $contentWrapper.addClass('is-hidden');
        $this.removeClass('is-active');

        setTimeout(function() {
            $([document.documentElement, document.body]).animate({
                scrollTop: $wrapper.offset().top
            }, 333);
        }, 333);
    } else {
        $this.addClass('is-active');
        $contentWrapper.removeClass('is-hidden');
        $contentWrapper.css('max-height', contentHeight+300);
        $this.html(closeText);

    }

});

/*=======================================
=            open close menu            =
=======================================*/

$(document).on('click', '.js_openCloseMenu', function() {
    var $this = $(this);
    var $menuOverlay = $this.closest('.nav').find('.menu-overlay');
    if ($this.hasClass('is-active')) {
        // $this.removeClass('is-active');
        // $menuOverlay.removeClass('is-active');
        closeMenu();
        bodyScroll.enable();
    } else {
        $this.addClass('is-active');
        $menuOverlay.addClass('is-active');
        bodyScroll.disable();
    }
});

function closeMenu() {
    $('.js_openCloseMenu').removeClass('is-active');
    $('.menu-overlay').removeClass('is-active');
    bodyScroll.enable();
}

/*====================================
=            lazxyloadimm            =
=====================================*/

// var lazyImages = document.querySelectorAll('.image');
// var inAdvance = 300;

// function lazyLoad() {
//     lazyImages.forEach(image => {
//         if (image.offsetTop < window.innerHeight + window.pageYOffset + inAdvance) {
//             console.log('trigger img');
//             image.src = image.dataset.src
//             image.onload = () => image.classList.add('loaded')
//         }
//     })

//     // if all loaded removeEventListener
// }

// lazyLoad();
// window.onresize = lazyLoad.debounce(250);
// window.onscroll = lazyLoad.debounce(250);

// new better mit intersections

// var observer = new IntersectionObserver(
// (entries, observer) => { 
// entries.forEach(entry => {
//     /* Placeholder replacement */
//     entry.target.src = entry.target.dataset.src;
//     entry.target.className += ' loaded';
//     console.log('trigger');
//     observer.unobserve(entry.target);
//   });
// }, 
// {rootMargin: "0px 0px 0px 0px"});

// document.querySelectorAll('.lazyIt').forEach(img => { observer.observe(img) });

// var myImg = document.querySelector('.lazyIt');

// var observer = new IntersectionObserver((entries, observer) => {
//     entries.forEach(entry => {
//         /* Placeholder replacement */

//         entry.target.src = entry.target.dataset.src;
//         entry.target.className += ' loaded';
//         console.log('trigger');
//         observer.unobserve(entry.target);
//     });
//     console.log('entry:', entry);
//     console.log('observer:', observer);
// });

// document.querySelectorAll('.lazyIt').forEach(img => { observer.observe(img) });
// observer.observe(myImg);


var imgs = document.querySelectorAll('.lazyIt');

var observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
            entry.target.src = entry.target.dataset.src;

            if (entry.target.dataset.srcset != undefined && entry.target.dataset.srcset !== false) {
                entry.target.srcset = entry.target.dataset.srcset;
            }
            entry.target.classList.add('loaded');

            observer.unobserve(entry.target);
            console.log('in view');
        } else {
        // console.log('out of view');
        }
    });
});

imgs.forEach(image => {
  observer.observe(image);
});



// var gmaps = document.querySelector('#gmaps');
// var gmapsObserver = new IntersectionObserver(entry => {
//     if (entry.intersectionRatio > 0) {
//         console.log('in view map');
        
//         entry.target.classList.add('loaded');

//         mapIt.initGmaps();

//         observer.unobserve(entry.target);
//     } else {
//     // console.log('out of view');
//     }
// });
// gmapsObserver.observe(gmaps);

// imgs.forEach(image => {
// });




/*====================================
=            resize stuff            =
====================================*/

$(window).on('resize', function() {


	// Check window width has actually changed and it's not just iOS triggering a resize event on scroll
    if ($(window).width() != windowWidth) {

        // Update the window width for next time
        windowWidth = $(window).width();

        if (!$('html').hasClass('is_fading')) {
	        // $('html').addClass('is_fading');
	    }
    }
    // Otherwise do nothing
})


    setTimeout(function() {
        $('html').removeClass('is_fading');
        $('svg').removeAttr('style');
    }, 200);
});