
; $ = global.$ = require("jQuery");
; var __browserify_shim_require__=require;(function browserifyShim(module, exports, require, define, browserify_shim__define__module__export__) {
// var $ = require('jQuery');

var cookie = {

    initCookie: function() {

        if ($('#cookie-popup').length) {
            var $cookiePopup = $('#cookie-popup');
            if (!cookie.checkCookie('cookiesaccepted')) {

                setTimeout(function() {
                    $cookiePopup.addClass('is_visible');
                }, 500);


                $(document).on('click', '.js_closeCookie',function() {
                    var $cookiePopup = $('#cookie-popup');

                    $cookiePopup.removeClass('is_visible');
                    
                    cookie.setCookie('cookiesaccepted', 'Cookies Accepted', 30);
                    setTimeout(function() {
                       $cookiePopup.remove();
                    }, 1000);
                })
            } else {
                $cookiePopup.remove();
            }
        }

    },

    setCookie: function(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    getCookie: function(cname) {
      
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";  

    },
    checkCookie: function(cname){

        var cookiesaccepted = cookie.getCookie(cname);
        if (cookiesaccepted != "") {
            return true;
        } else {
            return false;
        }

    },
}

cookie.initCookie();

cookie.exports = cookie;
; browserify_shim__define__module__export__(typeof cookIt != "undefined" ? cookIt : window.cookIt);

}).call(global, undefined, undefined, undefined, undefined, function defineExport(ex) { module.exports = ex; });
