
; $ = global.$ = require("jQuery");
require("flickity-imagesloaded");
require("js-throttle-debounce");
; var __browserify_shim_require__=require;(function browserifyShim(module, exports, require, define, browserify_shim__define__module__export__) {
// var $ = require('jQuery')
// var Flickity   = require('flickity');
var Flickity = __browserify_shim_require__('flickity-imagesloaded');
var debounce   = __browserify_shim_require__('js-throttle-debounce');

    var flickIt = {

        resizeModule: function() {
            if (!$('html').hasClass('isIos') && !$('html').hasClass('isAndroid')) {
                flickIt.initSliders("reload");
            }
        },
        initSliders: function(action) {
            if ($(".slider").length) {
                $(".slider").each(function() {
                    var id = $(this).attr("id");
                    var ww = $(window).outerWidth();
                    var actOpt = undefined;

                     if ($(this).hasClass('slider-specials')) {
                        actOpt = {
                            contain: true,
                            percentPosition: true,
                            prevNextButtons: true,
                            setGallerySize: true,
                            adaptiveHeight: false,
                            cellAlign: "center",
                            fade: false,
                            pageDots: false,
                            imagesLoaded: false,
                            initialIndex: 0,
                            // selectedAttraction: 0.05,
                            // friction: 0.6,
                            draggable: false,
                            resize: true,
                            // initialIndex: '.can be class so you can give it in php', 
                            cellSelector: '.slider-item',
                            wrapAround: false,
                            groupCells: 1,

                        };

                    } else if($(this).hasClass('slider-intro')) {
                        if (ww < 769) {
                             actOpt = {
                                contain: true,
                                percentPosition: true,
                                setGallerySize: false,
                                adaptiveHeight: false,
                                cellAlign: "center",
                                fade: false,
                                pageDots: false,
                                prevNextButtons: false,
                                imagesLoaded: true,
                                initialIndex: 0,
                                draggable: false,
                                resize: true,
                                autoPlay: 5000,
                                pauseAutoPlayOnHover: false,
                                cellSelector: '.picture-wrapper',
                                wrapAround: true,
                                groupCells: 1,
                            };
                        } else {
                            actOpt = {
                                contain: true,
                                percentPosition: true,
                                setGallerySize: true,
                                adaptiveHeight: false,
                                cellAlign: "center",
                                fade: false,
                                pageDots: false,
                                prevNextButtons: false,
                                imagesLoaded: true,
                                initialIndex: 0,
                                draggable: false,
                                resize: true,
                                autoPlay: 5000,
                                pauseAutoPlayOnHover: false,
                                cellSelector: '.picture-wrapper',
                                wrapAround: true,
                                groupCells: 1,
                            };
                        }
                    } else if($(this).hasClass('slider-realspecials')) {

                        actOpt = {
                            contain: true,
                            percentPosition: true,
                            prevNextButtons: false,
                            setGallerySize: true,
                            adaptiveHeight: false,
                            cellAlign: "center",
                            fade: false,
                            pageDots: true,
                            imagesLoaded: true,
                            initialIndex: 0,
                            watchCSS: true,
                            // selectedAttraction: 0.05,
                            // friction: 0.6,
                            draggable: true,
                            resize: true,
                            // initialIndex: '.can be class so you can give it in php', 
                            cellSelector: '.special-item',
                            wrapAround: false,
                            groupCells: 1,

                        };

                    } else if($(this).hasClass('slider-gallery')) {
                        if (ww < 769) {
                             actOpt = {
                                contain: true,
                                percentPosition: true,
                                setGallerySize: true,
                                adaptiveHeight: false,
                                cellAlign: "center",
                                fade: false,
                                pageDots: true,
                                prevNextButtons: false,
                                imagesLoaded: true,
                                initialIndex: 0,
                                // selectedAttraction: 0.05,
                                // friction: 0.6,
                                draggable: true,
                                resize: true,
                                // initialIndex: '.can be class so you can give it in php', 
                                cellSelector: '.slider-item',
                                wrapAround: true,
                                groupCells: 1,
                            };
                        } else {
                            actOpt = {
                                contain: true,
                                percentPosition: true,
                                prevNextButtons: true,
                                setGallerySize: true,
                                adaptiveHeight: false,
                                cellAlign: "center",
                                fade: false,
                                pageDots: false,
                                imagesLoaded: true,
                                initialIndex: 0,
                                // selectedAttraction: 0.05,
                                // friction: 0.6,
                                draggable: false,
                                resize: true,
                                // initialIndex: '.can be class so you can give it in php', 
                                cellSelector: '.slider-item',
                                wrapAround: true,
                                groupCells: 1,
                            };
                        }
                    } else {

                        // if (ww <= 1024) {
                        //     var actOpt = {

                        //         contain: true,
                        //         // percentPosition: true,
                        //         prevNextButtons: false,
                        //         // setGallerySize: false,
                        //         // adaptiveHeight: true,
                        //         cellAlign: "left",
                        //         // cellAlign: leftOffset / sliderWidth,
                        //         pageDots: true,
                        //         wrapAround: true,
                        //         groupCells: 1,
                        //     };
                        // } else {
                        //     var actOpt = {
                        //         contain: true,
                        //         // percentPosition: true,
                        //         prevNextButtons: false,
                        //         // setGallerySize: false,
                        //         // adaptiveHeight: true,
                        //         cellAlign: "left",
                        //         // cellAlign: leftOffset / sliderWidth,
                        //         pageDots: false,
                        //         wrapAround: true,
                        //         groupCells: 1,

                        //     };
                        // }
                    }


                    if (action == "reload") {

                        var elem = document.getElementById(id);
                        var flkty = Flickity.data(elem);

                        flkty.destroy();
                    }

                    flickIt.initSlider(id, actOpt);
                });
            }
        },
        initSlider: function(e, o) {
            // var elem = document.querySelector(e);
            var elem = document.getElementById(e);
            var options = {};
            if (o != undefined) {
                options = o;
            } else {
                options = {
                    cellAlign: "left",
                    contain: true,
                    // accessibility: false,
                    percentPosition: true,
                    autoPlay: 5000,
                    // freeScroll: true,
                    prevNextButtons: false,
                    setGallerySize: true,
                    // adaptiveHeight: true,
                    pageDots: false,
                    wrapAround: true
                };
            }

            setTimeout(function() {
                var flkty = new Flickity(elem, options);
                var event = "click";
                var www = $(window).outerWidth();


                // var $prevB = $(elem).parent().next().find('.button-prev');
                // var $nextB = $(elem).parent().next().find('.button-next');


                if ($(elem).find(".slider-buttons").length > 0) {
                    var $prevB = $(elem)
                        .find(".slider-buttons")
                        .find(".slider-button-prev");

                    var $nextB = $(elem)
                        .find(".slider-buttons")
                        .find(".slider-button-next");

                } else {
                    var $prevB = $(elem)
                        .closest(".section")
                        .find(".slider-button-prev");

                    var $nextB = $(elem)
                        .closest(".section")
                        .find(".slider-button-next");                    
                }


                if (options.draggable !== false) {
                    elem.ontouchmove = function (e) { // prevent mobile scroll out of viewport (whobble)
                        e.preventDefault();
                    }
                    document.ontouchmove = function (e) { // Enable scrolling.
                        return true; // Not use e.preventDefault()
                    }


                    // prevent click when items are links on drag
                    // $(elem).on('dragStart.flickity', () => $(elem).css('pointer-events', 'none'));
                    // $(elem).on('dragEnd.flickity', () => $(elem).css('pointer-events', 'all'));
                }

                var buttons = [$prevB, $nextB];

                $.each(buttons, function(index, el) {
                    $(el).unbind(event);
                    $(el).on(event, function(evt) {
                        evt.stopPropagation();
                        evt.preventDefault();
                        // console.log(event);

                        // console.log(flkty.slides.length, 'length:');
                        // console.log(index, 'index:');

                        // console.log('button_click');

                        if ($(this).hasClass("slider-button-next")) {
                            flkty.next(false, false);
                        } else {
                            flkty.previous(false, false);
                        }

                    });
                });

                var $nextslideButton = $(elem).closest('.special-item').find('.js_setnextslide');
                if ($nextslideButton.length > 0) {
                    $(document).on('click', $nextslideButton, function(e) {
                        e.stopPropagation();
                        e.preventDefault();
                        console.log(e.target);
                        if ($(e.target).hasClass('js_setnextslide')) {
                            flkty.next(false, false);
                        }
                        console.log('süecieal next cplid')
                    });
                }


                flkty.on( 'change', function( index ) {


                    var $slider = $(elem);
                    var $section = $slider.closest('.section');
                    var $sliderButtons = $section.find('.slider-buttons');


                    setTimeout(function() {

                        var ww = $(window).outerWidth();
                        // $(elem).hasClass('slider-insights')

                        

                        
                    }, 10);

                });

            }, 100);
        },

    }

    window.onresize = flickIt.resizeModule.debounce(250);

    flickIt.initSliders("init");

    // return flickIt;
    flickIt.exports = flickIt;
; browserify_shim__define__module__export__(typeof flickIt != "undefined" ? flickIt : window.flickIt);

}).call(global, undefined, undefined, undefined, undefined, function defineExport(ex) { module.exports = ex; });
